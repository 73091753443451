import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import LEFT_LOOPER from "../Assets/Images/footerlooperleft.png";
import RIGHT_LOOPER from "../Assets/Images/footerlooperright.png";
import LOGO from "../Assets/Images/outputsvg.png";
import FACEBOOK from "../Assets/Images/facebookIcon.png";
import INSTAGRAM from "../Assets/Images/InstagramIcon.png";
import STRAVA from "../Assets/Images/Strava.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import { toast } from "react-toastify";
const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    if (
      location.pathname === "/allblogs" ||
      location.pathname === "/termsandconditions" ||
      location.pathname === "/privacypolicy"
    ) {
      navigate("/");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleJoinUsClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      subject: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("full_name", values.name);
        formData.append("subject", values.subject);
        formData.append("email", values.email);
        formData.append("message", values.message);
        const res = await AxiosInstance.post(
          APILIST.SEND_CONTACTUS_MESSAGE,
          formData
        );
        if (res?.data?.s === 1) {
          resetForm();
          setOpen(false);
          toast.success(res?.data?.m);
        } else {
          resetForm();
          setOpen(false);
          toast.success(res?.data?.m);
        }
      } catch (error) {}
    },
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "linear-gradient(180deg, #0D0C14 100%, #3B3055 100%)",
          minHeight: "42vh",
          py: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            px: { xs: 2, lg: 25 },
          }}
        >
          <img
            src={LEFT_LOOPER}
            alt=""
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              width: "300px",
            }}
          />
          <img
            src={RIGHT_LOOPER}
            alt=""
            style={{
              position: "absolute",
              right: "0px",
              top: "15px",
              width: "280px",
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "start",
              gap: { xs: "50px", md: "0px" },
              pt: 3,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <img src={LOGO} alt="" style={{ width: "100px" }}></img>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "white",
                  fontSize: { xs: "14px", md: "14px" },
                  width: "250px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. ard dummy text ever since the 1500s,
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "200px",
                  gap: "20px",
                }}
              >
                <img
                  src={STRAVA}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
                <img
                  src={FACEBOOK}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
                <img
                  src={INSTAGRAM}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", color: "white" }}>
                    Menu
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => handleMenuClick()}
                      to="home"
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Home
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => handleMenuClick()}
                      to="whyus"
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Why us
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => handleMenuClick()}
                      to="pricing"
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Pricing
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => handleMenuClick()}
                      to="ourpartner"
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Our Partner
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => navigate("/faq")}
                      to="faq"
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      FAQs
                    </ScrollLink>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "25px",
                  ml: -2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", color: "white" }}>
                    Company
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={handleJoinUsClick}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Contact Us
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => handleMenuClick()}
                      to="support"
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Support
                    </ScrollLink>
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => navigate("/allblogs")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      All blogs
                    </ScrollLink>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ScrollLink
                      onClick={() => navigate("/privacypolicy")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      Privacy policy
                    </ScrollLink>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="black" gutterBottom>
            Contact Us
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "400px",
            }}
          >
            <Box>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="filled"
                name="name"
                sx={{
                  outline:
                    formik.touched.name && formik.errors.name
                      ? "2px solid red"
                      : "none",
                  border: "none",
                }}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
              />
              {formik.touched.name && formik.errors.name ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <TextField
                margin="dense"
                id="subject"
                label="Subject"
                type="text"
                fullWidth
                variant="filled"
                name="subject"
                sx={{
                  outline:
                    formik.touched.subject && formik.errors.subject
                      ? "2px solid red"
                      : "none",
                  border: "none",
                }}
                value={formik.values.subject}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
              />
              {formik.touched.subject && formik.errors.subject ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.subject}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                sx={{
                  outline:
                    formik.touched.email && formik.errors.email
                      ? "2px solid red"
                      : "none",
                  border: "none",
                }}
                variant="filled"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <TextField
                margin="dense"
                id="message"
                label="Message"
                type="text"
                fullWidth
                variant="filled"
                name="message"
                sx={{
                  outline:
                    formik.touched.message && formik.errors.message
                      ? "2px solid red"
                      : "none",
                  border: "none",
                }}
                value={formik.values.message}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
              />
              {formik.touched.message && formik.errors.message ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.message}
                </Typography>
              ) : null}
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#B393FF",
                color: "white",
                "&:hover": { bgcolor: "#B393FF", color: "white" },
              }}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;
