import React from "react";
import BACKGROUND from "../Assets/Images/pricingback.png";
import { Box, Button, Typography } from "@mui/material";
import APPSTORE_QR from "../Assets/Images/AppstoreQR.png";
import PLAYSTORE_QR from "../Assets/Images/PlaystoreQR.png";
import APPLEICON from "../Assets/Images/AppleIcon.png";
import ANDROIDICON from "../Assets/Images/AndroidIcon.png";
import PRICING_MOBILE from "../Assets/Images/pricingmobile.png";
import PartnerMarquee from "../Component/PartnerMarquee";

const Pricing = () => {
  return (
    <>
      <div id="pricing" style={{ padding: "100px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            background: `url(${BACKGROUND})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
            borderRadius: "88px",
          
          }}
        >
          <Box
            sx={{
              width: "100%",
              p: 5,
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "500",
                fontSize: { xs: "30px", md: "38px" },
              }}
            >
              Join Our App
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
              <Typography
                sx={{
                  color: "#ffffff80",
                  fontSize: { xs: "14px", md: "20px" },
                  fontWeight: "200",
                }}
              >
                Start your plan today for just
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "30px", xl: "34px" },
                  color: "white",
                }}
              >
                {" "}
                <span style={{ color: "#B190FF" }}>₹1,999.00 </span>per month /
                 <span style={{ color: "#B190FF" }}>₹9,900.00 </span> per year
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "50px",
                justifyContent: "start",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box
                sx={{
                  p: 2,
                  background: "#FFFFFF24",
                  borderRadius: "14px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "22px", fontWeight: "300" }}
                >
                  For ios
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "black",
                    background:
                      "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                    borderRadius: "20px",
                    width: "150px",
                  }}
                >
                  Download App
                </Button>
                <img src={APPSTORE_QR} alt="" width={70} height={70} />
                <img
                  src={APPLEICON}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "-20px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  p: 2,
                  background: "#FFFFFF24",
                  borderRadius: "14px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "22px", fontWeight: "300" }}
                >
                  For Android
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "black",
                    background:
                      "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                    borderRadius: "20px",
                    width: "150px",
                  }}
                >
                  Download App
                </Button>
                <img src={PLAYSTORE_QR} alt="" width={70} height={70} />
                <img
                  src={ANDROIDICON}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "-20px",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <img src={PRICING_MOBILE} alt="" style={{ width: "60%" }} />
          </Box>
        </Box>
      </div>
      <div id="ourpartner">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "30px", md: "42px" }, fontWeight: "500" }}
            >
              {" "}
              Our <span style={{ color: "#B190FF" }}>Partners</span>
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <PartnerMarquee />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Pricing;
